<template>
   <div class="w-full flex">
       <div class="md-layout-item md-size-50 mb-3">
        <TextAreaComponent
            class=""
            placeholder="Write Question"
            :rows=3
            v-model.trim="$v.title.$model" 
            @input="$emit('input', title)"
        />
       </div>
       <div class="md-layout-item md-size-30 mb-3">
            <div class="">
                <div class="form-group" v-for="(v, index) in $v.checkList.$each.$iter" v-bind:key="index">
                    
                    <InputFieldComponent placeholder="Option #1" @input="$emit('input', {options:{key:index, value:v.value.$model}, title})" v-model.trim="v.value.$model"  class="w-50 p-1" />
                    <div class="flex justify-center align-center md-layout-item md-size-100 mb-3">
                        <a class="ml-2 text-victoria font-bold text-md"   @click="checkList.push({key:1,value:''})" href="javascript:void(0)">Add New</a>
                    </div>
                    <span  style="margin-left: 435px; margin-top: -60px; position: fixed" class="bg-danger rounded-full pr-2 pl-2 h-5 text-center text-white"  @click="checkList.pop(), $emit('input', {remove:{key:index}})" aria-hidden="true">X</span>
                </div>
            </div>
       </div>
</div>
</template>

<script>

import { 
  InputFieldComponent,
  TextAreaComponent
  } from "@/components";
  import { required } from "vuelidate/lib/validators";
  
export default {
    components: {
       InputFieldComponent,
       TextAreaComponent,
    },
    props: ['value'],
    data() {
        return {
            checkList: [{
                key: '',
                value: '',
            }],
            title: ''
        }
    },
    methods: {

    },
    validations: {
        title: {
            required,
        },
        checkList: {
            required,
            $each: {
                key: {
                required,
                },
                value: {
                required,
                },
            }
        }
    }
}
</script>
