<template>
  <div class="w-full">
    <div class="md-layout-item flex justify-center mt-8">

    </div>
    <div class="md-layout-item">
      <TextEditor
          label="Title"
          v-model.trim="form.title"
          :message="!$v.form.title.required && $v.form.title.$dirty ? 'Title Field is required' : null"
      />
    </div>
    <div class="md-layout-item pt-2">
      <TextEditor
          label="Transcript"
          v-model.trim="form.transcript"
          :message="!$v.form.transcript.required && $v.form.transcript.$dirty ? 'Transcript Field is required' : null"
      />
    </div>
    <div class="md-layout-item py-4">
      <InputFieldComponent
          label="Keyword"
          v-model.trim="form.keywords"
      />
    </div>
  </div>
</template>

<script>
import InputFieldComponent from "../../../atom/form/InputFieldComponent";
import TextEditor from "@/components/atom/form/TextEditor";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    InputFieldComponent,
    TextEditor
  },
  props: ['value'],
  data() {
    return {
      form: {
        title: null,
        transcript: null,
        keywords: ''
      }
    }
  },
  validations: {
      form: {
          transcript: {required},
          title:{required}
      }
  }
}
</script>