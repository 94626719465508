<template>
  <div class="w-full">
    <div class="md-layout-item flex justify-center mt-8">
      <ImageUploader
          class="mt-6 -mt-4"
          :value="value"
          v-model.trim="form.file_path"
          :message="!$v.form.file_path.required && $v.form.file_path.$dirty ? 'Image Field is required' : null"
      />
    </div>
    <div class="md-layout-item py-4">
      <InputFieldComponent
          label="Keyword"
          v-model.trim="form.keywords"
      />
    </div>
  </div>
</template>

<script>
import InputFieldComponent from "../../../atom/form/InputFieldComponent";
import ImageUploader from "../../../atom/media/ImageUploader";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    ImageUploader,
    InputFieldComponent,
  },
  props: ['value'],
  data() {
    return {
      form: {
        keywords: '',
        file_path: '',
      }
    }
  },
  validations: {
      form: {
          file_path:{required}
      }
  }
}
</script>