<template>
  <div class="md-layout-item md-layout md-size-100">
      <!-- 05 ) full width row -->
      <div class="md-layout-item md-gutter md-size-100 mt-8">
        <div class="md-layout-item">
          <MicrophoneTest/>
        </div>
        <div class="md-layout-item listening__audio">
          <AudioUploader
            style="width: 450px; margin: 0 auto"
            :value="value"
            v-model.trim="form.file_path"
            :message="!$v.form.file_path.required && $v.form.file_path.$dirty ? 'Audio Field is required' : null"
          />
        </div>
      </div>

      <!-- 06 ) full width row -->
      <div class="md-layout-item md-layout md-size-100">
        <div class="md-layout-item md-layout md-gutter mt-8 md-size-100 z-9 align-baseline" style="margin-top: 50px!important;">
          <div class="md-layout-item md-size-65">
            <TextEditor
              class="read"
              label="Transcript"
              v-model.trim="$v.form.transcript.$model"
              :message="!$v.form.transcript.required && $v.form.transcript.$dirty ? 'Transcript Field is required' : null"
            />
          </div>
          <div class="md-layout-item md-size-35">
            <MultipleSelectAnswer
            @input="input"
              class="ml-2"
              :items="items"
              label="Answers"
              v-model.trim="$v.form.question_options.$model"
              :message="!$v.form.question_options.required && $v.form.question_options.$dirty ? 'Answers Field is required' : null"
            />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
import AudioUploader from "@/components/atom/media/AudioUploader";
import MultipleSelectAnswer from "@/components/molecule/question/MultipleSelectAnswer";
import MicrophoneTest from "@/components/molecule/question/MicrophoneTest.vue";
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        TextEditor,
        MultipleSelectAnswer,
        AudioUploader,
        MicrophoneTest
    },
    props: ["value"],
    data() {
        return {
            form: {
                transcript: "",
                question_options: [],
                file_path: '',
                full_marks: ''
            },
            items: [
              {title: '',value: '',is_correct_answer: false},
              {title: '',value: '',is_correct_answer: false},
              {title: '',value: '',is_correct_answer: false},
              {title: '',value: '',is_correct_answer: false}
            ]
        }
    },
    validations: {
        form: {
            transcript: {required},
            question_options: {required},
            file_path:{required}
        }
    },
    methods:{
      input(val){
         this.form.full_marks = val.filter(value => value.is_correct_answer === true).length
      }
    }
}
</script>