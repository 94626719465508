import Summarization from './Summarization';
import WriteFromDictation from "./WriteFromDictation";
import SummarizeSpokenTest from "./SummarizeSpokenTest";
import SelectMissingWord from "./SelectMissingWord";
import RetellLecture from "./RetellLecture";
import RepeatSentence from "./RepeatSentence";
import ReOrderParagraph from "./ReOrderParagraph";
import ReadAloud from "./ReadAloud";
import MCQSingleAnswers from "./MCQSingleAnswers";
import MCQMultipleAnswers from "./MCQMultipleAnswers";
import MCQChooseSingleAnswer from "./MCQChooseSingleAnswer";
import MCQChooseMultipleAnswer from "./MCQChooseMultipleAnswer";
import FillInTheGap from "./FillInTheGap";
import HighlightIncorrectWord from "./HighlightIncorrectWord";
import HighlightCorrectSummery from "./HighlightCorrectSummery";
import FillInTheGapDragAndDrop from "./FillInTheGapDragAndDrop";
import FillInTheGapDropDown from "./FillInTheGapDropDown";
import WritingEssay from "./WritingEssay";
import DescribeImage from "./DescribeImage";
import AnswerShortQuestion from "./AnswerShortQuestion";
import CheckListQuestion from "./CheckListQuestion";


export default {
    WriteFromDictation,
    Summarization,
    SummarizeSpokenTest,
    SelectMissingWord,
    RetellLecture,
    RepeatSentence,
    ReOrderParagraph,
    ReadAloud,
    MCQSingleAnswers,
    MCQMultipleAnswers,
    MCQChooseSingleAnswer,
    MCQChooseMultipleAnswer,
    FillInTheGap,
    HighlightIncorrectWord,
    HighlightCorrectSummery,
    FillInTheGapDragAndDrop,
    FillInTheGapDropDown,
    WritingEssay,
    DescribeImage,
    AnswerShortQuestion,
    CheckListQuestion
}