<template>
   <div class="md-layout-item md-layout md-size-100">
      <!-- 05 ) full width row -->
      <div class="md-layout-item md-gutter md-size-100 mt-8">
        <div class="md-layout-item">
          <MicrophoneTest/>
        </div>
        <div class="md-layout-item listening__audio">
          <AudioUploader
            style="width: 450px; margin: 0 auto"
            :value="value"
            v-model.trim="form.file_path"
            :message="!$v.form.file_path.required && $v.form.file_path.$dirty ? 'Audio Field is required' : null"
          />
        </div>
      </div>
      <div class="md-layout-item md-layout md-gutter mt-8 md-size-100">
        <div class="md-layout-item">
          <TextEditor
            label="Transcript"
            v-model.trim="$v.form.transcript.$model"
            :message="!$v.form.transcript.required && $v.form.transcript.$dirty ? 'Transcript Field is required' : null"
            />
        </div>
      </div>
      <div class="md-layout-item md-layout md-size-100">
        <div class="md-layout-item md-layout md-gutter md-size-100 mt-16 z-9 align-baseline" style="margin-top: 40px!important;">
          <div class="md-layout-item md-size-70" id="indexAssign">
               <h2 class="text-uppercase text-victoria mb-3">Question</h2>
                <TextEditordrag
                  class="read question_note"
                  label="Question of the HIW-use 3rd bracket to add options[answer;option]"                  
                  @input="input"
                  :message="!$v.form.title.required && $v.form.title.$dirty ? 'Question Field is required' : null"
              />
          </div>
          <div class="md-layout-item md-size-30">
            <h2 class="text-uppercase text-victoria mb-3">Answers</h2>
              <DragAndDropInput
                  label="Answers"
                  class="reading md-answer_option md-text-white"
                  :items="items"
                  :question_type="question_type"
              />
          </div>
        </div>
      </div>
   </div>
</template>

<script>
import AudioUploader from "@/components/atom/media/AudioUploader";
import TextEditor from "@/components/atom/form/TextEditor";
import TextEditordrag from "@/components/atom/form/TextEditor";
import DragAndDropInput from "@/components/atom/DragAndDropInput";
import MicrophoneTest from "@/components/molecule/question/MicrophoneTest.vue";
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        TextEditor,
        AudioUploader,
        DragAndDropInput,
        TextEditordrag,
        MicrophoneTest,
    },
    data() {
        return {
            form: {
                transcript: "",
                title: "",
                question_options: [],
                file_path: '',
                full_marks: null
            },
            correct: [],
            Incorrect:[],
            items: [],
            data:[],
            question_type:"HIW"
        }
    },
    props: ["value"],
    methods:{
        handleListAllItalicts() {
            const targetDOMs = document.querySelectorAll('#indexAssign i');
            if(targetDOMs.length > 0) {
                window.form_italics = targetDOMs;
                for(let i = 0; i < targetDOMs.length; i++){
                    targetDOMs[i].classList.add("italics_number");
                    targetDOMs[i].id ="italics_number"+`${i+1}`;
                }
            } 
        },
        input(val){
          this.handleListAllItalicts();
          const value = val.match(/ (\/)?\[[^\]]*]/g);
          this.form.title = val.toString().replace(/[[*]/g, '<span class="finding">').replace(/;/g, '</span><span class="hidden">').replace(/]/g, '</span>')
          const text = val.replace(/<[^>]+>/g, '')
          const Arrays = text.split(" ");
                
          //const itemss = myArray.map(value => value.name.toString().replace(/<[^>]+>/g, '').replace(/[[^>]/g, '').replace(/]/g, ''));
          //const datas = itemss.map(value => value.name.toString().split(";"));
          //console.log('ss',myArray)
          if(value != null){
             const answer = Arrays.map((item,index) => {
                return {
                  name: item.toString().replace(/<[^>]+>/g, '').replace(/[[^>]/g, '').replace(/]/g, '').toString().split(";"),
                  serial_no: index+1
                }
              }); 
              const items = value.map(value => value.toString().replace(/<[^>]+>/g, '').replace(/[[^>]/g, '').replace(/]/g, ''));
              const data = items.map(value => value.toString().split(";"));
              this.items = data.map(value => [{value:value[0]}])
             //console.log(answer)
              this.data = [
                ...answer
                ].map(item => {
                  delete item.index;
                  if(item.name[1] !== undefined){
                  return {
                    value: item.name[0].replace(/\s/g, ''),
                    title: item.name[1].replace(/\s/g, ''),
                    serial_no: item.serial_no,
                  };
                  }
                   
              });
              const answer_final = this.data.filter(Boolean)
 
              this.form.question_options = answer_final;
              this.form.full_marks = answer_final.length
              //console.log(this.data.filter(Boolean))
          }
        }
    },
    validations: {
        form: {
            title:{required},
            transcript: {required},
            question_options: {required},
            file_path:{required}
        }
    }
}
</script>