<template>
   <div class="md-layout-item md-layout md-size-100">
      <div class="md-layout-item md-gutter md-size-100 mt-8">
        <div class="md-layout-item">
          <MicrophoneTest/>
        </div>
        <div class="md-layout-item listening__audio">
          <AudioUploader
              style="width: 450px; margin: 0 auto"
              :value="value"
              v-model.trim="form.file_path"
              :message="!$v.form.file_path.required && $v.form.file_path.$dirty ? 'Audio Field is required' : null"
          />
        </div>
      </div>
      <div class="md-layout-item">
        <TextEditor
           label="Transcript"
           v-model.trim="$v.form.transcript.$model"
           :message="!$v.form.transcript.required && $v.form.transcript.$dirty ? 'Transcript Field is required' : null"
        />
      </div>
   </div>
</template>

<script>
import AudioUploader from "@/components/atom/media/AudioUploader";
import TextEditor from "@/components/atom/form/TextEditor";
import { required} from "vuelidate/lib/validators";
import { useVuelidate } from 'vuelidate/'
import MicrophoneTest from "../MicrophoneTest.vue";
export default {
    components: {
        AudioUploader,
        TextEditor,
        MicrophoneTest
    },
    props: ["value"],
    data() {
      return {
        form: {
          transcript: '',
          question_options: [],
          option_type : "RADIO",
          file_path: ''
        },
        items: [
          {title: ''},
          {title: ''},
          {title: ''},
          {title: ''}
        ]
      }
    },
    setup: () => ({ v$: useVuelidate() }),
    validations: {
      form: {
        transcript: {required},
        file_path: {required}
      }
    }
}
</script>