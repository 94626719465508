<template>
  <div>
    <h2 class="text-uppercase text-victoria mb-3">{{ label }}</h2>
    
    <ul>
      <li v-for="(item, index) in items" :key="index" class="my-3 flex align-center">
        <md-radio v-model="selectedAnswerId" :value="item.id" class="md-primary ml-2"> </md-radio>
        
        <!-- global section -->
        <InputFieldComponent v-if="dropdown != 'dropdown' && dropdown != 'correctWord'" v-model="item.title" class="w-74" />
        
        <!-- correct word section -->
        <InputAreaComponent v-if="dropdown == 'correctWord'" v-model="item.title" class="w-text" />
        
        <!-- drop_down section -->
        <InputFieldComponent v-if="dropdown == 'dropdown'" v-model="item.title" :disabled="index > 0 ? false : true" class="w-74" />
      </li>
    </ul>
    <b class="text-danger" v-if="message">{{ message }}</b>
  </div>
</template>

<script>
import { InputFieldComponent } from '@/components'
import { InputAreaComponent } from '@/components'
export default {
  components: {
    InputFieldComponent,
    InputAreaComponent
  },
  props: {
    label: {
      type: String
    },
    labelOutline: {
      type: Boolean,
      default: () => true
    },
    message: {
      type: String,
      default: () => null
    },
    dropdown: {
      type: String,
      default: () => null
    },
    items: {
      type: Array,
      default: () => [
        { id: 1, label: "", is_correct_answer: false },
        { id: 2, label: "", is_correct_answer: false },
        { id: 3, label: "", is_correct_answer: false },
        { id: 4, label: "", is_correct_answer: false },
      ]
    }
  },
  data: () => ({
    selectedAnswerId: null,
  }),
  watch: {
    selectedAnswerId(newValue) {
      this.items.forEach(item => {
        item.is_correct_answer = item.id === newValue;
        item.value = item.title;
      });

      this.$emit('input', this.items);
    }
  },
  mounted() {
    const correctAnswer = this.items.find(item => item.is_correct_answer);
    if (correctAnswer) {
      this.selectedAnswerId = correctAnswer.id;
    }
  }
};
</script>

<style></style>
