<template>
  <div class="w-full">
    <div class="md-layout-item flex justify-center mt-8">
      <AudioUploader
          class="mt-6 -mt-4"
          :value="value"
          v-model.trim="form.file_path"
          :message="!$v.form.file_path.required && $v.form.file_path.$dirty ? 'Audio Field is required' : null"
      />
    </div>
    <div class="md-layout-item">
      <TextEditor
          label="Transcript"
          v-model.trim="form.transcript"
          :message="!$v.form.transcript.required && $v.form.transcript.$dirty ? 'Transcript Field is required' : null"
      />
    </div>
    <div class="md-layout-item py-4">
      <InputFieldComponent
          label="Keyword"
          v-model.trim="form.keywords"
      />
    </div>
  </div>
</template>

<script>
import AudioUploader from "@/components/atom/media/AudioUploader";
import InputFieldComponent from "../../../atom/form/InputFieldComponent";
import TextEditor from "@/components/atom/form/TextEditor";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    AudioUploader,
    InputFieldComponent,
    TextEditor
  },
  props: ['value'],
  data() {
    return {
      form: {
        transcript: null,
        keywords: '',
        file_path: ''
      }
    }
  },
  validations: {
      form: {
          transcript: {required},
          file_path:{required}
      }
  }
}
</script>