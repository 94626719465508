<template>
  <form id="myForm" @submit.prevent="onSubmit" class="p-0">
    <!-- Title Section -->
    <div class="my-3">
      <label class="text-uppercase inline-block font-bold mb-3">
        Write the {{ type_name.name }}
      </label>
      <TextEditor
        :label="false"
        v-model.trim="form.title"
        :message="!$v.form.title.required && $v.form.title.$dirty ? 'Index Field is required' : null"
      />
    </div>

    <!-- Materials Section -->
    <div class="flex flex-col mt-3">
      <div class="flex align-baseline">
        <label class="w-20p text-uppercase inline-block font-bold mb-2">
          Choose Material
        </label>
        <div class="w-85p">
          <div v-for="(material, index) in form.materials" :key="index" class="ml-20 flex align-center my-1">
            <Material :name="'Browse'" :materialIds="(materials[index] && materials[index].file_name) ? material : form.materials" :title="materials[index] ? materials[index].file_name : ''" :type_name="type_name" v-model="form.materials[index]" />
            <md-button v-if="index === 0" @click="addMaterial" class="md-icon-button md-dense md-primary">
              <md-icon>add</md-icon>
            </md-button>
            <md-button v-else @click="removeMaterial(index)" class="md-icon-button md-dense">
              <md-icon class="text-danger">remove</md-icon>
            </md-button>
          </div>
        </div>
      </div>
    </div>

    <!-- External Links Section -->
    <div class="flex flex-col mt-3">
      <div class="flex align-baseline">
        <label class="w-20p text-uppercase inline-block font-bold mb-2">
          Write important links
        </label>
        <div class="w-85p">
          <div v-for="(link, index) in form.external_links" :key="index" class="ml-20 flex align-center my-2">
            <InputFieldComponent placeholder="URL" v-model="form.external_links[index]" class="basis mr-2" />
            <InputFieldComponent placeholder="Link text" v-model="form.external_link_titles[index]" class="basis" />
            <md-button v-if="index === 0" @click="addLink" class="md-icon-button md-dense md-primary">
              <md-icon>add</md-icon>
            </md-button>
            <md-button v-else @click="removeLink(index)" class="md-icon-button md-dense">
              <md-icon class="text-danger">remove</md-icon>
            </md-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Required Lessons Section -->
    <div class="coming_soon">
      <div class="flex flex-col mt-3">
        <div class="flex align-baseline">
          <label class="w-20p text-uppercase inline-block font-bold mb-2">
            Required lesson should be completed
          </label>
          <div class="w-85p">
            <div v-for="(lesson, index) in form.required_lessons" :key="index" class="ml-20 flex align-center my-1">
              <Material :name="'Browse Lesson'" :type_name="type_name" v-model="form.required_lessons[index]" />
              <md-button v-if="index === 0" @click="addRequiredLesson" class="md-icon-button md-dense md-primary">
                <md-icon>add</md-icon>
              </md-button>
              <md-button v-else @click="removeRequiredLesson(index)" class="md-icon-button md-dense">
                <md-icon class="text-danger">remove</md-icon>
              </md-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Required Quizzes Section -->
      <div class="flex flex-col mt-3">
        <div class="flex align-baseline">
          <label class="w-20p text-uppercase inline-block font-bold mb-2">
            Required Quiz should be completed
          </label>
          <div class="w-85p">
            <div v-for="(quiz, index) in form.required_quizzes" :key="index" class="ml-20 flex align-center my-1">
              <Material :name="'Browse Quiz'" :type_name="type_name" v-model="form.required_quizzes[index]" />
              <md-button v-if="index === 0" @click="addRequiredQuiz" class="md-icon-button md-dense md-primary">
                <md-icon>add</md-icon>
              </md-button>
              <md-button v-else @click="removeRequiredQuiz(index)" class="md-icon-button md-dense">
                <md-icon class="text-danger">remove</md-icon>
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Submit Section -->
    <div class="my-3 flex justify-between">
      <div class="active">
        <md-checkbox v-if="type_name.id === '0'" v-model="form.active">
          Mark as active
        </md-checkbox>
      </div>
      <div>
        <md-button @click="dialog(false)" class="bg-default text-uppercase rounded">
          Cancel
        </md-button>
        <md-button :loading="loading" type="submit" class="bg-victoria text-white text-uppercase rounded">
          Save
        </md-button>
      </div>
    </div>
  </form>
</template>

<script>
import { InputFieldComponent } from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import Material from "../materialFile.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Material,
    InputFieldComponent,
    TextEditor,
  },
  props: {
    type_name: {
      type: Object
    },
    question_id: {
      type: String,
    },
    study_guide_id: {
      type: String
    }
  },
  data() {
    return {
      form: {
        question_type_id: "",
        title: "",
        materials: [""],
        external_links: [""],
        external_link_titles: [""],
        required_lessons: [""],
        required_quizzes: [""],
        active: true,
        is_default: false,
        is_archived: false,
      },
      materials: [],
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      getSelectedQuestionType: "question/getSelectedQuestionType"
    })
  },
  watch: {
    'form.title'() {
        this.$v.form.title.$touch();
      },
  },
  methods: {
    ...mapActions({
      actSaveStudyGuide: "studyGuide/actSaveStudyGuide",
      actListStudyGuide: "studyGuide/actListStudyGuide",
      actShowStudyGuide: "studyGuide/actShowStudyGuide",
      actUpdateStudyGuide: "studyGuide/actUpdateStudyGuide",
    }),
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    onSubmit() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          this.loading = false;
          return;
      }
      this.form.question_type_id = this.getSelectedQuestionType.id;
      delete this.form.required_lessons ;
      delete this.form.required_quizzes ;
      if (this.form.materials && this.form.materials.length === 0 || this.form.materials.every(item => item === "")) {
        delete this.form.materials;
      }
      if (this.study_guide_id) {
        this.actUpdateStudyGuide({
          explanationId: this.study_guide_id,
          payload: this.form
        })
          .then(message => {
            this.setShowSnackbar(message);
            this.loading = false;
            this.dialog(false);
            this.actListStudyGuide(``);
          }).catch(error => {
              this.setShowSnackbar(error.response.data.message);
              this.loading = false;
          });
      } else {
        this.actSaveStudyGuide(this.form)
          .then(({ message }) => {
            this.setShowSnackbar(message);
            this.dialog(false);
            this.actListStudyGuide(``);
            this.loading = false;
          }).catch(error => {
              this.setShowSnackbar(error.response.data.message);
              this.loading = false;
          });
      }
    },
    fetchStudyGuideDetails() {
      this.loading = true
      this.actShowStudyGuide(this.study_guide_id).then(item => {
        this.loading = false;
        this.form.title = item.title || "";
        this.form.materials = item.materials.map(item => item.pivot.material_id) || [""];
        this.materials = item.materials || [""];
        this.form.external_link_titles = item.external_link_titles || [""];
        this.form.external_links = item.external_links || [""];
        this.form.required_lessons = item.required_lessons || [""];
        this.form.required_quizzes = item.required_quizzes || [""];
      }).catch(error => {
        console.error(error)
        this.loading = false;
      });
    },
    addMaterial() {
      this.form.materials.push('');
    },
    removeMaterial(index) {
      if (this.form.materials.length > 1) {
        this.form.materials.splice(index, 1);
      }
    },
    addLink() {
      this.form.external_links.push('');
      this.form.external_link_titles.push('');
    },
    removeLink(index) {
      if (this.form.external_links.length > 1) {
        this.form.external_links.splice(index, 1);
        this.form.external_link_titles.splice(index, 1);
      }
    },
    addRequiredLesson() {
      this.form.required_lessons.push('');
    },
    removeRequiredLesson(index) {
      if (this.form.required_lessons.length > 1) {
        this.form.required_lessons.splice(index, 1);
      }
    },
    addRequiredQuiz() {
      this.form.required_quizzes.push('');
    },
    removeRequiredQuiz(index) {
      if (this.form.required_quizzes.length > 1) {
        this.form.required_quizzes.splice(index, 1);
      }
    }
  },
  validations: {
    form: {
      title: { required },
    },
  },
  created() {
      this.study_guide_id && this.fetchStudyGuideDetails();
  }
};
</script>

<style types>
  .coming_soon {
    pointer-events: none;
    background: #d2d2d2;
    position: relative;
    padding: 0px 8px;
  }
  .coming_soon::before{
    content: 'Coming soon';
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
  }
</style>
