<template>
   <div class="md-layout-item md-layout md-size-100">
      <!-- 05 ) full width row -->
      <div class="md-layout-item md-gutter md-size-100 mt-8">
        <div class="md-layout-item">
          <MicrophoneTest/>
        </div>
        <div class="md-layout-item listening__audio">
          <AudioUploader
            style="width: 450px; margin: 0 auto"
            :value="value"
            v-model.trim="form.file_path"
            :message="!$v.form.file_path.required && $v.form.file_path.$dirty ? 'Audio Field is required' : null"
          />
        </div>
      </div>
      <div class="md-layout-item md-layout md-size-100">
        <div class="md-layout-item md-layout md-gutter mt-8 md-size-100 z-9 align-baseline" style="margin-top: 40px!important;">
          <div class="md-layout-item md-size-70">
            <TextEditor
              class="read"
              label="transcript"
              v-model.trim="$v.form.transcript.$model"
              :message="!$v.form.transcript.required && $v.form.transcript.$dirty ? 'Transcript Field is required' : null"
              />
          </div>
          <div class="md-layout-item md-size-30">
            <MCQAnswer
              class=""
              :items="items"
              label="Answers"
              v-model.trim="$v.form.question_options.$model"
              :message="!$v.form.question_options.required && $v.form.question_options.$dirty ? 'Answers Field is required' : null"
            />
          </div>
        </div>
      </div>
   </div>
</template>

<script>
import AudioUploader from "@/components/atom/media/AudioUploader";
import TextEditor from "@/components/atom/form/TextEditor";
import MCQAnswer from "@/components/molecule/question/MultipleChoiseAnswer";
import { required } from "vuelidate/lib/validators";
import MicrophoneTest from "../MicrophoneTest.vue";
export default {
    components: {
        AudioUploader,
        TextEditor,
        MCQAnswer,
        MicrophoneTest
    },
    data() {
        return {
            form: {
                transcript: "",
                question_options: [],
                option_type : "RADIO",
                file_path: ''
            },
            items: [
              {title: ''},
              {title: ''},
              {title: ''},
              {title: ''}
            ]
        }
    },
    props: ["value"],
    validations: {
        form: {
            transcript: {required},
            question_options: {required},
            file_path: {required}
        }
    }
}
</script>