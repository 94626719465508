<template>
  <div class="p-2 md-align_center" style="width: 94%">
    <div class="pt-3 pb-2 flex align-center justify-between" style="border-bottom: 1px solid #a6a6a67d">
      <h2 class="flex align-center">SET EXPLANATION <small class="pl-2 text-victoria">{{content.section}}</small> </h2>
      <md-button @click="dialog(false)" class="md-icon-button md-dense">
        <md-icon>close</md-icon>
      </md-button>
    </div>
    <div class="md-layout-item md-layout md-size-100">
      <div class="my-3 mb-1">
        <label class="text-uppercase font-bold">Manage Explanination</label>
        <RadioButtonComponent
          active-class="font-semibold"
          label-class="text-capitalize"
          :items="[
            { id: 'a', label: 'Create New' },
            { id: 'b', label: 'Select from Archive' },
          ]"
          v-model.trim="type"
        />
      </div>
      <div class="md-layout-item md-size-100" v-if="type == 'a'" >
        <!-- <ExplanationView justShow v-if="getExplanations != null" :getExplanations="getExplanations" :type_name="type_name"/> -->
        <ExplanationCreate :question_id="question_id" :type_name="type_name"/>
      </div>
      <div v-else class="md-layout-item md-size-100">
         <ArchiveList :content="content" :type_name="type_name" :question_id="question_id" :getExplanations="getExplanation" :getExplanationlist="getExplanationlist"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  RadioButtonComponent,
} from "@/components";
import ArchiveList from "@/components/molecule/question-and-test-management/material/ArchiveList.vue";
import ExplanationCreate from "@/components/molecule/question-and-test-management/material/CreateNew.vue";
//import ExplanationView from "@/components/molecule/question-and-test-management/material/ListView.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    ExplanationCreate,
    //ExplanationView,
    RadioButtonComponent,
    ArchiveList
  },
  props: {
    question_id: {
      type: String
    },
    content: {
      type: Object,
    },
  },
  data() {
      return {
          type: 'a',
          label: 'Create',
          createForm: false,
          type_name: {
            name:'explanation',
            id: 'e_id'
          },
      }
  },
  computed: {
    ...mapGetters({
      getExplanations: "explanation/getExplanations",
      getExplanationlist: "explanation/getExplanationlist",
      getSelectedQuestionType: "question/getSelectedQuestionType",
      getExplanation: "explanation/getExplanation",
    }),
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    ...mapActions({
        actQuestionListExplanation: "explanation/actQuestionListExplanation",
        actListExplanation: "explanation/actListExplanation",
        actShowExplanation: "explanation/actShowExplanation",
    })
  },
  created() {
    this.actQuestionListExplanation(``);
    this.actListExplanation(`?question_type_id=${this.getSelectedQuestionType.id}&is_archived=0`)
    setTimeout(() => {
      this.actShowExplanation(`${this.getExplanationlist[0].id}`)
    }, 1000);
    
  }
};
</script>

<style></style>
