<template>
  <div>
    <h2 class="text-uppercase text-victoria mb-3">{{ label }}</h2>
    <ul class="left_answer">
      <li v-for="(item, index) in items" :key="index" class="my-3 flex align-center">
         <!-- <md-checkbox v-model="item.is_answer"></md-checkbox> -->
         <span class="check_index">{{index+1}}</span>
         <span v-for="(items, index) in item" :key="index" style="display: contents;">
           <InputFieldComponent v-model="items.value"  v-html="items.value" class="border border-solid rounded border-gray-400 input-box-shadow p-2 w-74 disable"/>
           <!-- <span v-html="items.x" class="w-74 disable"></span> -->
        </span>
        <!-- <InputFieldComponent v-model="item.serial_no" class="w-12 ml-5" /> -->
      </li>
    </ul>
    <span id="scrollTop" class="text-xs text-danger" v-if="message">{{ message }}</span>
  </div>
</template>

<script>
import { InputFieldComponent } from '@/components'
export default {
  components: {
    InputFieldComponent
  },
  data: () => ({
    boolean: false,
  }),
  props: {
    label: {
      type: String
    },
    labelOutline: {
      type: Boolean,
      default: () => true
    },
    message: {
      type: String,
      default: () => null
    },
   
    items: {
      type: Array,
      default: () => [
        { id: 1, label: "Answer 01" },
        { id: 2, label: "Answer 02" },
        { id: 3, label: "Answer 03" },
        { id: 4, label: "Answer 04" },
      ]
    }
  },
  watch: {
    boolean(value) {
      this.items.is_answer = value;
      this.$emit('input', this.items);
    }
  },
  methods:{
    
  },

};
</script>