<template>
    <div class="md-layout-item md-layout md-size-100">
      <div class="md-layout-item md-layout md-gutter md-size-100 mt-16">
         <div class="md-layout-item" id="indexAssign">
            <h2 class="text-uppercase text-victoria mb-3">Paragraph</h2>
            <TextEditor
                class="read question_note"
                label="Question of the FGDD-use 3rd bracket to add options[answer-1;option-2;option-3;option-4]"
                v-model.trim="$v.form.title.$model"
                :message="!$v.form.title.required && $v.form.title.$dirty ? 'Question Field is required' : null"
                @input="input"
            />
         </div>
      </div>
      <div class="md-layout-item md-layout md-gutter md-size-100 mt-16" style="align-items: baseline;">
            <div
                class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-33 z-95"
                v-for="(item, index) in items"
                :key="index+1"
            >
                <MCQAnswer
                    :items="item"
                    :index_array="`${index+1}`"
                    :dropdown="dropdown"
                    :label="'Answers '+`${index+1}`"
                    v-model.trim="form.question_options[index]"
                />
            </div>
        </div>
   </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
import MCQAnswer from "@/components/molecule/question/DropdwonMultipleAnswer.vue";
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        TextEditor,
        MCQAnswer
    },
    props: ['value'],
    data() {
        return {
            dropdown: 'dropdown',
            form: {
                title: '',
                question_options: [],
            },
            items: [],
            item: [],
            data:[]
        }
    },
    mounted(){
        window.form_italics = [];
    },
    methods:{
        handleListAllItalicts() {
            const targetDOMs = document.querySelectorAll('#indexAssign i');
            if(targetDOMs.length > 0) {
                window.form_italics = targetDOMs;
                for(let i = 0; i < targetDOMs.length; i++){
                    targetDOMs[i].classList.add("italics_number");
                    targetDOMs[i].id ="italics_number"+`${i+1}`;
                }
            } 
        },
        input(val){
            this.handleListAllItalicts();
            let title = val.match(/[*^[](.*?)]*]/g);
            if(title != null){
                this.item = title.map(title => [
                    title.toString().replace(/[[^>]/g, '').replace(/]/g, '').replace(/&nbsp;/gi,"")
                ]);

                this.items = this.item.map(title => title.toString().split(";"))

                for( var i = 0; i < this.items.length; i++){ 
                    this.items[i] =  this.items[i].map(element=> ({
                        dd_value: element,
                        
                    }))

                    this.items.map(item => {delete item.dd_is_correct_answer,delete item.key});

                    this.items[i].forEach((item, i) => {
                        item.dd_key = i + 1;
                        item.dd_serial_no = i + 1;

                        if(i < 1){
                            item.dd_is_correct_answer = true;
                        } else {
                            item.dd_is_correct_answer =false;  
                        }
                    });

                    this.form.question_options[i] = {drop_down_options: this.items[i], serial_no: i+1}
                }
            }
        },
        clearData() {
            this.form= {
                title: '',
                question_options: [],
            };
            this.data= [];
            this.items= [];
            this.item= [];
        }
    },
    validations: {
        form: {
            title: {required}
        }
    }
}
</script>