<template>
   <div class="md-layout-item md-size-100">
        <div class="md-layout-item  mt-8">
            <TextEditor
                label="Question"
                v-model.trim="$v.form.title.$model"
                @input="input"
                :message="!$v.form.title.required && $v.form.title.$dirty ? 'Title Field is required' : null"
            />
        </div>
        <div class="md-layout-item mt-8 p-4 bg-light-yellow">
            <ContentBox
               label="Scoring"
               :contents='contents'
               @input="contentData"
            />
            <div class="mt-6">
               <VocabularyBox :contents='contents' :content='content' @input="vocabularyData"/>
            </div>
        </div>
   </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
import ContentBox from "@/components/atom/form/contentBox.vue";
import VocabularyBox from "@/components/atom/form/VocabularyBox.vue";
import {required} from "vuelidate/lib/validators";
export default {
    name: "WritingEassay",
    components: {
        TextEditor,
        ContentBox,
        VocabularyBox
    },
    props: {
        value: String
    },
    data() {
        return {
            contents: '',
            content:[],
            vocabulari: [],
            vocabulary: [],
            form: {
                title: "",
                contents: [],
                vocabularies: []
            },
            
        }
    },
    methods: {
        input(val){
            console.log('eer',val)
            this.contents = val
        },
        contentData(val){
            this.content = val;
            this.form.contents = {content_array: val};
            this.vocabularyData();    
        },
        vocabularyData(val){
            this.vocabulary = val 
            const merge  = this.content.concat(this.vocabulary);
            const results = merge.filter(element => {
               return element !== undefined;
            });
            console.log('tag31',results)
            this.form.vocabularies = {vocabulary_array: results};
        }
    },
    validations: {
        form: {
          title: {required},
        }
    }
    
}
</script>