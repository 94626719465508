<template>
   <div class="md-layout-item md-layout md-size-100">
        <!-- 05 ) full width row -->
        <div class="md-layout-item md-gutter md-size-100 mt-8">
            <div class="md-layout-item">
            <MicrophoneTest/>
            </div>
            <div class="md-layout-item listening__audio">
            <AudioUploader
                style="width: 450px; margin: 0 auto"
                :value="value"
                v-model.trim="form.file_path"
                :message="!$v.form.file_path.required && $v.form.file_path.$dirty ? 'Audio Field is required' : null"
            />
            </div>
        </div>
        <div class="md-layout-item md-layout md-size-100">
            <div class="md-layout-item md-size-100">
                    <TextEditor
                        class="read"
                        label="transcript"
                        v-model.trim="$v.form.transcript.$model"
                        :message="!$v.form.transcript.required && $v.form.transcript.$dirty ? 'Transcript Field is required' : null"
                    />
            </div>
        </div>
        <div class="md-layout-item md-layout md-size-100">
            <div class="md-layout-item md-layout md-gutter md-size-100 mt-16 z-9 align-baseline" style="margin-top: 40px!important;">
                <div class="md-layout-item md-size-70" id="indexAssign">
                    <h2 class="text-uppercase text-victoria mb-3">Question</h2>
                    <TextEditor
                        class="read question_note"
                        label="Question of the FG-use 3rd bracket to add options[answer]"
                        @input="input"
                        v-model.trim="$v.form.title.$model"
                        :message="!$v.form.title.required && $v.form.title.$dirty ? 'Question Field is required' : null"
                    />
                </div>
                <div class="md-layout-item md-size-30">
                    <h2 class="text-uppercase text-victoria mb-3">Answers</h2>
                    <DragAndDropInput
                        label="."
                        class="reading md-answer_option md-text-white"
                        :items="items"
                    />
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
import DragAndDropInput from "@/components/atom/DragAndDropInput";
import AudioUploader from "@/components/atom/media/AudioUploader";
import MicrophoneTest from "@/components/molecule/question/MicrophoneTest.vue";
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        TextEditor,
        AudioUploader,
        DragAndDropInput,
        MicrophoneTest,
    },
    props: ['value'],
    data() {
        return {
            form: {
                option_type : "GAP_DRAG_DROP",
                transcript: "",
                title: "",
                question_options: [],
                file_path: '',
                full_marks: null
            },
            i:[],
            data: [],
            items: [],
        }
    },
    methods:{
        handleListAllItalicts() {
            const targetDOMs = document.querySelectorAll('#indexAssign i');
            if(targetDOMs.length > 0) {
                window.form_italics = targetDOMs;
                for(let i = 0; i < targetDOMs.length; i++){
                    targetDOMs[i].classList.add("italics_number");
                    targetDOMs[i].id ="italics_number"+`${i+1}`;
                }
            } 
        },
        input(val){
          this.handleListAllItalicts();
          const value = val.match(/ (\/)?\[[^\]]*]/g);
          console.log('item',value)
          if(value != null){
              this.data = value.map(value => value.replace(/<[^>]+>/g, '').replace(/[[^>]/g, '').replace(/]/g, ''));
              this.items = value.map(value => [{value: value.replace(/<[^>]+>/g, '').replace(/[[^>]/g, '').replace(/]/g, '')}]);
              console.log(this.items)
              for( var i = 0; i < this.data.length; i++){ 
                  this.data[i] = {title: this.data[i],is_correct_answer: true}
                  const arrayAnswer = [
                      ...this.data,
                  ].map((item, index) => {
                      delete item.index;
                      return {
                      key: index + 1,
                      serial_no: index + 1,
                      ...item,
                      value: item.title,
                  };
                  });
                  console.log(arrayAnswer)
                  this.form.question_options = arrayAnswer;
                  this.form.full_marks = arrayAnswer.length
              }
          }
        }
    },
    validations: {
        form: {
            title: {required},
            transcript: {required},
            question_options: {required},
            file_path: {required}
        }
    }
}
</script>