<template>
  <div class="mb-8 view_list_create">
    <Card>
      <CardBody class="p-3 relative">
        <Table :items="getExplanations" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{item.sl}}</md-table-cell>
            <md-table-cell md-sort-by="eId" :md-label="type_name.id" class="text-victoria">{{item.exp_id}}</md-table-cell>
            <md-table-cell md-sort-by="exTitle" :md-label="type_name.name" class="w-74 ex-title_dot" v-html="item.exp_title"></md-table-cell>
            <md-table-cell md-sort-by="createdBy" md-label="CREATED BY">{{item.created_by}}</md-table-cell>
            <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{item.assigned_at}}</md-table-cell>
            <md-table-cell md-sort-by="status" md-label="STATUS" 
            :class="[toLower((item.active == 1) ? 'Active': 'InActive') == 'active' ? 'text-green' : 'text-danger']">{{ (item.active == 1) ? 'Active': 'Inactive' }}</md-table-cell>
          </template>
        </Table>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-2 pb-2" style="border-bottom: 1px solid rgba(166, 166, 166, 0.49);zoom: 85%;">
        <Paginate
          :start="meta.current_page"
          :end="meta.last_page"
          :total="meta.last_page"
          :limit="meta.per_page"
          @on-start="onStart"
          @on-end="onEnd"
        />
    </div>
  </div>
</template>

<script>
import {
    Card,
    Table,
    CardBody,
    Paginate,
    } from "@/components";

import cms from "@/data/cms";
import { paginate } from "@/mixins/paginate";
import { mapGetters,mapActions} from "vuex";
import { query } from "@/utils/queryString";
export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Paginate,
        CardBody,
  },
  props: {
    type_name: {
      type: Object
    },
    // getExplanations: {
    //   type: Array
    // }
  },
  data() {
      return {
          currentIndex: 0,
          content: '',
          title: '',
          component: 'ExplanationCreate',
          types: cms.types,
          keyword: '',
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          explanations:[]
      }
  },
  computed: {
      ...mapGetters({
        getExplanations: "explanation/getExplanations",
        meta: "explanation/getExplanationQuestionListMetaMeta",
      }),
  },
  watch: {
    keyword() {
      
    }
  },
   methods: {
    onChangeStatus() {
      
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
    ...mapActions({
        actQuestionListExplanation: "explanation/actQuestionListExplanation",
    }),
    onStart(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actQuestionListExplanation(`?${query.get()}`);
    },
    onEnd(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actQuestionListExplanation(`?${query.get()}`);
    },
  },
  created() {
    this.actQuestionListExplanation(``);
  }

}
</script>