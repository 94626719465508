<template>
    <div class="p-2 m-2">
        <div class="flex justify-between align-center pl-5">
          <div class="flex align-center text-uppercase">
          <h2 class="text-victoria">Sample Answer</h2>
          <h4 class="pl-3 mt-1">Writing</h4>
          </div>
          <md-button @click="dialog(false)" class="md-icon-button md-dense">
              <md-icon>close</md-icon>
          </md-button>
        </div>
        
        <div class="p-2 mt-4 w-full p-1">
          <h4 class="pl-3">Select Sample Answer</h4>
          <div v-if="content" class="md-layout-item md-layout md-gutter md-size-100 align-baseline">
            <div class="md-layout-item md-size-30">
              <div class="bg-victoria text-white rounded border border-solid border-gray-500 p-1 mt-2 pl-2 cursor">
                <h3>Population of Canada</h3>
                <p>SAM_Q88 1200</p>
              </div>
              <div class="rounded border border-solid border-gray-500 p-1 mt-2 pl-2 cursor">
                <h3 class="text-black">Population of Canada</h3>
                <p>SAM_Q88 1200</p>
              </div>
              <div class="rounded border border-solid border-gray-500 p-1 mt-2 pl-2 cursor">
                <h3 class="text-black">Population of Canada</h3>
                <p>SAM_Q88 1200</p>
              </div>
              <div class="rounded border border-solid border-gray-500 p-1 mt-2 pl-2 cursor">
                <h3 class="text-black">Population of Canada</h3>
                <p>SAM_Q88 1200</p>
              </div>
              <div class="rounded border border-solid border-gray-500 p-1 mt-2 pl-2 cursor">
                <h3 class="text-black">Population of Canada</h3>
                <p>SAM_Q88 1200</p>
              </div>
            </div>
            <div class="md-layout-item md-size-70 rounded border border-solid border-gray-500">
              <div class="p-1">
                <p class="text-black text-justify">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
              </div>
            </div>
          </div>
            <span v-else class="p-5 w-full text-center">
              <h3 class="text-gray">Empty Sample Answer</h3>
            </span>
        </div>
        <div class="flex justify-end align-center mt-6 mr-2">
            <Button
                class="rounded text-black mr-0 border border-solid border-gray-500 mr-4"
                label="Clear"
                type="submit"
                >
            </Button>
            <Button
                class="bg-victoria rounded text-white mr-0"
                label="Collect"
                type="submit"
                >
            </Button>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapActions,mapMutations} from "vuex";
import {
  Button,
} from "@/components";
export default {
  components: {
    Button
  },
  props:{
    content: {
      default: true
    },
  },
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters({
      getSampleAnswers: "sampleAnswer/getSampleAnswers",
    }),
    
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    ...mapActions({
      actSaveSampleAnswer: "sampleAnswer/actSaveSampleAnswer"
    }),
  },
};
</script>