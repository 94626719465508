<template>
  <div class="summerized" style="display:contents"> 
    <div class="md-layout-item md-layout md-size-100">
      <div class="md-layout-item md-gutter md-size-100 mt-8">
        <div class="md-layout-item">
          <MicrophoneTest/>
        </div>
        <div class="md-layout-item listening__audio">
          <AudioUploader
              style="width: 450px; margin: 0 auto"
              :value="value"
              v-model.trim="form.file_path"
              :message="!$v.form.file_path.required && $v.form.file_path.$dirty ? 'Audio Field is required' : null"
          />
        </div>
      </div>
    </div>
    <div class="md-layout-item md-layout md-size-100">
      <div class="md-layout-item">
        <TextEditor
        class="read"
            label="Transcript"
            v-model.trim="$v.form.transcript.$model"
            @input="input"
            :message="!$v.form.transcript.required && $v.form.transcript.$dirty ? 'Transcript Field is required' : null"
        />
      </div>
    </div>
    <div class="md-layout-item md-layout md-size-100">
      <div class="md-layout-item  mt-8">
          <TextEditor
              label="Question"
              v-model.trim="$v.form.title.$model"
              :message="!$v.form.title.required && $v.form.title.$dirty ? 'Title Field is required' : null"
          />
      </div>
    </div>
    <div class="md-layout-item md-layout md-size-100">
      <div class="md-layout-item mt-8 p-4 bg-light-yellow content__modal">
          <ContentBox
              label="Scoring"
              :contents='contents'
              @input="contentData"
          />
          <div class="mt-6 vocabulary__modal">
              <VocabularyBox :contents='contents' :content='content' @input="vocabularyData"/>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioUploader from "@/components/atom/media/AudioUploader";
import MicrophoneTest from "../MicrophoneTest.vue";
import TextEditor from "@/components/atom/form/TextEditor";
import ContentBox from "@/components/atom/form/contentBox.vue";
import VocabularyBox from "@/components/atom/form/VocabularyBox.vue";
import {required} from "vuelidate/lib/validators";
export default {
  components: {
    AudioUploader,
    TextEditor,
    ContentBox,
    VocabularyBox,
    MicrophoneTest
  },
  props: ["value"],
  data() {
    return {
      contents: '',
      content:[],
      vocabulari: [],
      vocabulary: [],
      form: {
        transcript: "",
        title: '',
        option_type : "RADIO",
        file_path: '',
        contents: [],
        vocabularies: []
      },
      items: [
        {title: ''},
        {title: ''},
        {title: ''},
        {title: ''}
      ]
    }
  },
  methods: {
    input(val){
        this.contents = val;
        console.log('input',val)
    },
    // contentData(val){
    //     this.content = val;
    //     this.form.contents = {content_array: val};
    //     this.DataMerge(val)
    // },
    // DataMerge(val){
    //   this.vocabulary = val
    //   const array = this.vocabulari.concat(...this.content,...this.vocabulary);
    //   const merge = [...new Set(array)];
    //   this.form.vocabularies = {vocabulary_array: merge};
    //   console.log('tag',this.form.vocabularies)
    // },
    // vocabularyData(val){
    //   this.DataMerge(val)
    // }
    contentData(val){
        this.content = val;
        this.form.contents = {content_array: val};
        this.vocabularyData();    
    },
    vocabularyData(val){
        this.vocabulary = val 
        const merge  = this.content.concat(this.vocabulary);
        const results = merge.filter(element => {
            return element !== undefined;
        });
        console.log('tag31',results)
        this.form.vocabularies = {vocabulary_array: results};
    }
  },
  validations: {
    form: {
      transcript: {required},
      title: {required},
      file_path: {required}
    }
  }
}
</script>