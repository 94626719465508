<template>
  <form id="myForm" @submit.prevent="onSubmit" class="p-0">
    <div class="my-3 mt-2">
      <h2 class="mb-4">{{(this.explanation_id ? 'Edit' : 'Create New')}} {{type_name.name}}</h2>
    </div>
    <div class="my-3">
      <label class="text-uppercase inline-block font-bold mb-3">Write the {{type_name.name}}</label>
      <TextEditor
        :label="false"
        v-model.trim="form.title"
        :message="!$v.form.title.required && $v.form.title.$dirty ? 'Title Field is required' : null"
      />
    </div>
    <div class="flex flex-col mt-3">
      <div class="my-0 mb-2">
        <label class="text-uppercase inline-block font-bold mb-3">Note</label>
        <TextEditor
          :label="false"
          v-model.trim="form.notes"
        />
      </div>
    </div>
    <div class="flex flex-col mt-3">
      <div class="flex align-baseline"> 
        <label class="w-20p text-uppercase inline-block font-bold mb-2">Choose Material</label>
        <div class="w-85p">
          <div v-for="(material, index) in form.materials" :key="index" class="ml-20 flex align-center my-1">
            <Material :name="'Browse'" :materialIds="(materials[index] && materials[index].file_name) ? material : form.materials" :title="materials[index] ? materials[index].file_name : ''" :type_name="type_name" v-model="form.materials[index]" />
            <md-button v-if="index === 0" @click="addMaterial" class="md-icon-button md-dense md-primary">
              <md-icon>add</md-icon>
            </md-button>
            <md-button v-else @click="removeMaterial(index)" class="md-icon-button md-dense">
              <md-icon class="text-danger">remove</md-icon>
            </md-button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-3">
      <div class="flex align-baseline"> 
        <label class="w-20p text-uppercase inline-block font-bold mb-2">Write important links</label>
        <div class="w-85p">
          <div v-for="(link, index) in form.external_links" :key="index" class="ml-20 flex align-center my-2">
            <InputFieldComponent placeholder="URL" v-model="form.external_links[index]" class="basis mr-2" />
            <InputFieldComponent placeholder="Link text" v-model="form.external_link_titles[index]" class="basis" />
            <md-button v-if="index === 0" @click="addLink" class="md-icon-button md-dense md-primary">
              <md-icon>add</md-icon>
            </md-button>
            <md-button v-else @click="removeLink(index)" class="md-icon-button md-dense">
              <md-icon class="text-danger">remove</md-icon>
            </md-button>
          </div>
        </div>
      </div>
    </div>
    <div class="my-3 flex justify-between">
      <div class="active">
        <md-checkbox v-if="type_name.id === '0'" v-model="form.active">
          Mark as active
        </md-checkbox>
      </div>
      <div>
        <md-button @click="dialog(false)" class="bg-default text-uppercase rounded">Cancel</md-button>
        <md-button :loading="loading" type="submit" class="bg-victoria text-white text-uppercase rounded">Save & SET</md-button>
      </div>
    </div>
  </form>
</template>

<script>
import {
  InputFieldComponent,
} from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import Material from "@/components/molecule/question-and-test-management/material/materialFile.vue";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    Material,
    InputFieldComponent,
    TextEditor,
  },
  props: {
    type_name: {
      type: Object
    },
    question_id: {
      type: String,
    },
    explanation_id: {
      type: String
    },
  },
  data() {
    return {
      loading: false,
      form: {
        question_type_id: "",
        title: "",
        materials: [""],
        external_links: [""],
        external_link_titles: [""],
        active: false,
        questions: [],
        notes:""
      },
      materials: [],
      showMediaDialog: false,
      linkCount: 0,
      questionSection: '',
      url_name: '',
      merge:[],
    };
  },
  computed: {
      ...mapGetters({
          getSelectedQuestionType: "question/getSelectedQuestionType"
      })
  },
  watch: {
    'form.title'() {
        this.$v.form.title.$touch();
      },
  },
  methods: {
    ...mapActions({
      //actGetQuestionTypes: "question/actGetQuestionTypes",
      actSaveExplanation: "explanation/actSaveExplanation",
      actListExplanation: "explanation/actListExplanation",
      actShowExplanation: "explanation/actShowExplanation",
      actUpdateExplanation: "explanation/actUpdateExplanation"
    }),
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    input(val){
      console.log(val)
      if(val.url){
        this.url_name = val.url;
      } else{
        this.url_name = val[0].name;
      }
    },
    addMaterial() {
      this.form.materials.push('');
    },
    removeMaterial(index) {
      if (this.form.materials.length > 1) {
        this.form.materials.splice(index, 1);
      }
    },
    addLink() {
      this.form.external_links.push('');
      this.form.external_link_titles.push('');
    },
    removeLink(index) {
      if (this.form.external_links.length > 1) {
        this.form.external_links.splice(index, 1);
        this.form.external_link_titles.splice(index, 1);
      }
    },
    onClear(){
        var frm = document.getElementById('myForm');
        frm.reset();  // Reset
        return false; // Prevent page refresh
    },
    onSubmit() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          this.loading = false;
          return;
      }
      this.form.question_type_id = this.getSelectedQuestionType.id;
      this.form.questions = { [this.question_id] : { is_default: false } }
      if(this.form.materials[0] === ""){
        delete this.form.materials
      }
      if(this.form.external_link_titles[0] === ""){
        delete this.form.external_link_titles
      }
      if(this.form.external_links[0] === ""){
        delete this.form.external_links
      }
      if (this.explanation_id) {
        this.actUpdateExplanation({
          explanationId: this.explanation_id,
          payload: this.form
        })
          .then(message => {
            this.setShowSnackbar(message);
            this.loading = false;
            this.dialog(false);
          }).catch(error => {
              this.setShowSnackbar(error.response.data.message);
              this.loading = false;
          });
      } else {
        this.actSaveExplanation(this.form)
        .then(({ message }) => {
          this.setShowSnackbar(message);
          this.dialog(false);
          this.loading = false;
        })
      }
    },
    fetchExplanationDetails () {
      this.actShowExplanation(`${this.explanation_id}`).then( item => {
          this.form.title = item.title || "";
          this.form.notes = item.notes || "";
          this.form.materials = item.materials.length && item.materials.map(item => item.pivot.material_id) || [""];
          this.materials = item.materials || [""];
          this.form.external_link_titles = item.external_link_titles && item.external_link_titles || [""];
          this.form.external_links = item.external_links && item.external_links || [""];
      })
    }
  },
  validations: {
    form: {
      title: { required },
    },
  },
  mounted() {
    this.explanation_id && this.fetchExplanationDetails()
  }
};
</script>
