<template>
  <div class="p-2 md-align_center" style="width: 94%">
    <div class="pt-3 pb-2 flex justify-between align-center" style="border-bottom: 1px solid #a6a6a67d">
      <h2 class="flex align-center">Create Sample Answer <small class="pl-2 text-victoria">{{content.section}}</small></h2>
      <md-button @click="dialog(false)" class="md-icon-button md-dense">
        <md-icon>close</md-icon>
      </md-button>
    </div>
    <RadioButtonComponent
      active-class="font-semibold"
      label-class="text-capitalize"
      :items="[
        { id: 'a', label: 'Create New' },
        { id: 'b', label: 'Choose From Archive' }
      ]"
      v-model.trim="type"
    />
    <div class="md-layout-item md-size-100" v-if="type == 'a'" >
        <ListView  :type_name="type_name" v-if="type == null"/>
        <CreateNew :question_id="question_id" :type="types" :type_name="type_name" :content="content"/>
    </div>
    <div v-else class="md-layout-item md-size-100">
      <ArchiveList :content="content" :question_id="question_id" :type="types" :type_name="type_name" :getSampleAnswersLists="getSampleAnswersList"/>
    </div>
  </div>
</template>

<script>
import { RadioButtonComponent} from "@/components";
import ArchiveList from "@/components/molecule/question-and-test-management/material/SampleAnswer/ArchiveList.vue";
import CreateNew from "@/components/molecule/question-and-test-management/material/SampleAnswer/CreateNew.vue";
import ListView from "@/components/molecule/question-and-test-management/material/SampleAnswer/ListView.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    RadioButtonComponent,
    ArchiveList,
    CreateNew,
    ListView
  },
  props: {
    content: {
      type: Object,
    },
    types:{
      type:String
    },
    question_id: {
      type: String
    }
  },
  data() {
    return {
      type: 'a',
      type_name:{
        name:'Sample Answer',
        id: "S_id"
      },
      form: {
        title: '',
        resource: [],
        active: true
      }
    }
  },
   computed: {
    ...mapGetters({
      getSampleAnswersList: "sampleAnswer/getSampleAnswersList",
      getSelectedQuestionType: "question/getSelectedQuestionType",
      getSampleAnswerShow: "sampleAnswer/getSampleAnswerShow",
    }),
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    ...mapActions({
      actSampleAnswerQuestionList: "sampleAnswer/actSampleAnswerQuestionList",
      actAnswerListByQuestion: "sampleAnswer/actAnswerListByQuestion",
      actShowSampleAnswer: "sampleAnswer/actShowSampleAnswer",
    })
  },
  created() {
    this.actSampleAnswerQuestionList(`?question_type_id=${this.content.id}&is_archived=0`);
  }
};
</script>
