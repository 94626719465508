<template>
  <div class="flex align-center my-3">
      <h4 class="w-48 text-uppercase text-center" style="margin-left: -5px;">microphone test</h4>
      <div class="flex outline-gray-400 p-2 basis rounded z-9">
        <div class="p-1 m-0 block bg-victoria inline-block rounded-full">
            <md-icon class="m-1 p-0  text-white">volume_up</md-icon>
        </div>
        <div class="flex align-center basis ml-3">
            <div class="h-2 bg-victoria basis mx-1"></div>
            <div class="h-2 bg-victoria basis mx-1"></div>
            <div class="h-2 bg-victoria basis mx-1"></div>
            <div class="h-2 bg-victoria basis mx-1"></div>
            <div class="h-2 bg-victoria basis mx-1"></div>
            <div class="h-2 bg-default  basis mx-1"></div>
            <div class="h-2 bg-default  basis mx-1"></div>
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>